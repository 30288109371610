import { graphql } from 'gatsby'
import React from 'react'
import { BlogPostListItem } from '../components/blog-post-list-item'
import Layout from '../components/layout'
import SEO from '../components/seo'

const BlogIndex = props => {
  const { data } = props
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={props.location}>
      <SEO title="Blog-indlæg" />
      <h1>Blog-indlæg</h1>
      {posts.map(({ node }) => (
        <BlogPostListItem
          author={node.frontmatter.author}
          date={node.frontmatter.date}
          excerpt={node.excerpt}
          key={node.fields.slug}
          slug={node.fields.slug}
          title={node.frontmatter.title}
          wordCount={node.wordCount.words}
        />
      ))}
    </Layout>
  )
}

export default BlogIndex

export const PageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: { pageType: { eq: "blog_post" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt(format: HTML)
          fields {
            slug
          }
          frontmatter {
            author
            date(formatString: "Do MMMM YYYY", locale: "da")
            title
          }
          wordCount {
            words
          }
        }
      }
    }
  }
`
