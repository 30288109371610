import { Link } from 'gatsby'
import React from 'react'
import styles from './blog-post-list-item.module.css'

export const BlogPostListItem = ({
  author,
  date,
  excerpt,
  slug,
  title,
  wordCount
}) => (
  <article className={styles.item}>
    <h2 className={styles.title}>
      <Link to={slug}>{title}</Link>
    </h2>
    <div
      className={styles.content}
      dangerouslySetInnerHTML={{ __html: excerpt }}
    />
    <small className={styles.byline}>
      {wordCount} ord af {author}, {date}
    </small>
    <Link to={slug}>Læs mere…</Link>
  </article>
)
