import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './header.module.css'

const menuLinks = [
  {
    name: 'Velkommen',
    link: '/'
  },
  {
    name: 'Blogs',
    link: '/blogs'
  },
  {
    name: 'Opskrifter',
    link: '/opskrifter'
  }
]

const Header = ({ siteTitle }) => (
  <header className={`site-header ${styles.header}`}>
    <div className={styles.content}>
      <h1 className={styles.sitename}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`
          }}
        >
          {siteTitle}
        </Link>
      </h1>

      <nav className={styles.menu}>
        <ul>
          {menuLinks.map(link => (
            <li key={link.name}>
              <Link to={link.link}>{link.name} </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
